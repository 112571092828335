html,
body,
* {
	cursor: url('./ui-cursor.png') 0 0, default !important;

	button:hover,
	button:active {
		cursor: url('./ui-cursor-active-highlight.png') 0 0, pointer !important;
	}

	canvas {
		cursor: url('./canvas-cursor.png') 44 29, pointer !important;
	}
}

.ActiveCursor() {
	cursor: url('./ui-cursor-active-highlight.png') 0 0, pointer !important;
}
